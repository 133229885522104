<template>
  <section class="m-setting-terms m-scroll">

    <!-- 头部导航 -->
    <m-header title="条款" ></m-header>

  

      <div class="main">

      <router-link to="/zh/setting/clause/use"  class="setting-cell van-hairline--bottom">
        <div class="label">
          <span>使用条款</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to="/zh/setting/clause/privacy"  class="setting-cell van-hairline--bottom">
        <div class="label">
          <span>隐私条款</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to="/zh/setting/clause/pay"  class="setting-cell van-hairline--bottom">
        <div class="label">
          <span>服务条款</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to="/zh/setting/clause/shipping"  class="setting-cell van-hairline--bottom">
        <div class="label">
          <span>配送条款</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to="/zh/setting/clause/returns"  class="setting-cell van-hairline--bottom">
        <div class="label">
          <span>退货/退款条款</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to="/zh/setting/clause/security"  class="setting-cell van-hairline--bottom">
        <div class="label">
          <span>网络安全协议</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>


      

    </div>






    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Security',
  components:{ MHeader },
  data(){
    return {
      account:{
        phone:'18068411355',
        // email:'416994573@qq.com',
        email:'',
        wechat:'Ace_柴',
        fb:'',
        ins:'',
      }
      
    }
  },

  

  methods:{

    // 退出当前账号
    handleSignOut(){
      this.$router.push({path:'/zh/login'})
    },

    // 点击邮箱 
    handleEmail(){
      if(this.account.email){
        this.$router.push({path:'/zh/setting/checkemail'})
      }else{
        this.$router.push({path:'/zh/setting/bindemail'})
      }
    }
   
  },
}
</script>

<style lang="less" scoped>
.m-setting-terms {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow: hidden;
  .main {
    background-color: #fff;
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    .setting-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      .label, .right {
        display: flex;
        align-items: center;
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .right {
        display: flex;
        align-items: center;
        height: 50px;
        .back {
          width: 18px;
          height: 18px;
          margin-left: 4px;
        }
      }
    }
  }
}
</style>